@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');
body{
  font-family: "Outfit", sans-serif;
}

/* In your CSS file or a styled component */
@keyframes move-up-down {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px); /* Adjust this value for how high you want it to move up */
  }
  100% {
    transform: translateY(0);
  }
}

.moving-image {
  animation: move-up-down 1s ease-in-out infinite; /* Adjust duration and easing as needed */
}
