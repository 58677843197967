@tailwind base;
@tailwind components;
@tailwind utilities;

/* For WebKit browsers */
::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
    height: 12px; /* Height of the scrollbar for horizontal scrollbar */
}

::-webkit-scrollbar-track {
    background: white; /* Background color of the track */
    border-radius: 6px; /* Rounded corners for the track */
}

::-webkit-scrollbar-thumb {
    background: #1E3A8A;/* Color of the scrollbar thumb */
    border-radius: 6px; /* Rounded corners for the thumb */
}

::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
}